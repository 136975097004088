import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {QuizService} from '../../services/quiz.service';

@Component({
  selector: 'lib-front-screen',
  templateUrl: './front-screen.component.html',
  styleUrls: ['./front-screen.component.scss']
})
export class FrontScreenComponent implements OnInit {

  get backUrl() {
    return this._backUrl;
  }
  @Input()
  set backUrl(backUrl: string) {
    this._backUrl = backUrl;
  }
  private _backUrl: string;

  @Input()
  set variantId (variantId: string) {
    this._variantId = variantId;
  }
  private _variantId: string;

  get numberOfQuestions() {
    return this._numberOfQuestions;
  }
  @Input()
  set numberOfQuestions (numberOfQuestions: number) {
    this._numberOfQuestions = numberOfQuestions;
  }
  private _numberOfQuestions: number;

  get testingTime() {
    return this._testingTime;
  }
  @Input()
  set testingTime (testingTime: number) {
    this._testingTime = testingTime;
  }
  private _testingTime: number;

  get title() {
    return this._title;
  }
  @Input()
  set title(title: string) {
    this._title = title;
  }
  private _title: string;

  @Output()
  startVariant: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private readonly quizService: QuizService
  ) { }

  ngOnInit(): void {
  }

  goToIom() {
    if (this._backUrl) {
      window.location.href = this._backUrl;
    }
  }

  startQuiz() {
    this.startVariant.emit();
  }
}
