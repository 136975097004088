import {ModuleWithProviders, NgModule} from '@angular/core';
import { RnimuQuizComponent } from './rnimu-quiz.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatDialogModule, MatDialogTitle} from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {MatRadioModule} from '@angular/material/radio';
import { MatToolbarModule } from '@angular/material/toolbar';
import { StoreModule } from '@ngrx/store';
import { reducer } from './reducers';
import { EffectsModule } from '@ngrx/effects';
import { RnimuQuizEffects } from './rnimu-quiz.effects';
import { MainLayoutComponent } from './shared/components/main-layout/main-layout.component';
import { QuizPageComponent } from './quiz-page/quiz-page.component';
import { StatusIndicatorComponent } from './shared/components/status-indicator/status-indicator.component';
import {
  QuestionsListMobileDialogComponent,
  QuizFinishingConfiirmComponent, QuizTabEscapeDalogComponent
} from './quiz-page/quiz-page.component';
import { QuizComponent } from './shared/components/quiz/quiz.component';
import { QuizNavigationComponent } from './shared/components/quiz-navigation/quiz-navigation.component';
import { QuestionComponent } from './shared/components/question/question.component';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { QuestionsListComponent } from './shared/components/questions-list/questions-list.component';
import {VariantService} from './shared/services/variant.service';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ScreenService} from './shared/services/screen.service';
import {MatRippleModule} from '@angular/material/core';
import {RnimuGalleryModule} from '@rsmu/gallery';
import {FrontScreenComponent} from './shared/components/front-screen/front-screen.component';
import {MatSidenavModule} from '@angular/material/sidenav';


@NgModule({
    imports: [
        MatButtonModule,
        StoreModule.forFeature('variantState', reducer),
        EffectsModule.forFeature([RnimuQuizEffects]),
        MatToolbarModule,
        MatCardModule,
        CommonModule,
        MatRadioModule,
        FormsModule,
        ReactiveFormsModule,
        MatListModule,
        MatCheckboxModule,
        MatGridListModule,
        MatProgressBarModule,
        MatExpansionModule,
        MatIconModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatRippleModule,
        RnimuGalleryModule,
        MatSidenavModule
    ],
  declarations: [
    RnimuQuizComponent,
    MainLayoutComponent,
    QuizPageComponent,
    StatusIndicatorComponent,
    QuizComponent,
    QuizNavigationComponent,
    QuestionComponent,
    QuestionsListComponent,
    QuestionsListMobileDialogComponent,
    QuizFinishingConfiirmComponent,
    QuizTabEscapeDalogComponent,
    FrontScreenComponent
  ],
  exports: [
    RnimuQuizComponent,
  ],
  providers: [ VariantService, ScreenService ],
  entryComponents: [QuestionsListMobileDialogComponent, QuizTabEscapeDalogComponent]
})
export class RnimuQuizModule {
  static forRoot(configuration): ModuleWithProviders<RnimuQuizModule> {
    return {
      ngModule: RnimuQuizModule,
      providers: [{
        provide: 'env',
        useValue: configuration
      }]
    };
  }
}


