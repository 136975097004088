<div class="question">
  <div class="question"
       (swipeleft)="goToNextQuestion()"
       (swiperight)="goToPreviousQuestion()"
  >
    <mat-card-title><div class="question-title-text" [innerHTML]="question.content"></div></mat-card-title>
    <lib-rnimu-gallery *ngIf="question.imgUrls.length > 0" [images]="question.imgUrls"></lib-rnimu-gallery>
    <mat-card-content class="question-content">
      <div class="question-content">
        <ng-container *ngTemplateOutlet="answerSegmentTemplate"></ng-container>
      </div>
    </mat-card-content>
    <mat-card-actions class="mat-card-actions-container">
      <ng-container *ngTemplateOutlet="buttonsSegment"></ng-container>
    </mat-card-actions>
  </div>
</div>

<ng-template #answerSegmentTemplate>
  <span  [ngSwitch]="question.type" *ngIf="question.answers">
    <span *ngSwitchCase="'single'">
      <div class="mat-card-question__type">Выберите ОДИН правильный ответ</div>
      <mat-radio-group [disabled]="!isElementsDisabled"
                       class="radio-group_answer"
      >
        <mat-radio-button class="radio-button_answer"
                          [ngClass]="{'checkbox-button_answer_selected': answer.selectedByUser}"
                          value="{{answer.id}}"
                          *ngFor="let answer of question.answers"
                          (change)="setSelectedSingleAnswer(answer)"
                          [checked]="answer.selectedByUser"
        >
          <span class="question-inner-html-text" [innerHTML]="answer.content"></span>
        </mat-radio-button>
      </mat-radio-group>
    </span>
    <div *ngSwitchCase="'multi'">
      <div class="mat-card-question__type">Выберите НЕСКОЛЬКО правильных ответов</div>
      <span class="checkbox-group_answer">
            <mat-list-item
              [ngClass]="{'checkbox-button_answer_selected': answer.selectedByUser}"
              class="checkbox-button_answer"
              *ngFor="let answer of question.answers"
            >
              <mat-checkbox
                [disabled]="!isElementsDisabled"
                (change)="setSelectedMultiAnswer(answer, $event)"
                [checked]="answer.selectedByUser"
              >
                <span class="question-inner-html-text" [innerHTML]="answer.content"></span>
              </mat-checkbox>
            </mat-list-item>
      </span>
    </div>
  </span>
</ng-template>

<ng-template #buttonsSegment>
  <div class="question-buttons">
    <button class="question-buttons-stroked" mat-stroked-button
            [disabled]="isFirstQuestion || !isElementsDisabled"
            (click)="goToPreviousQuestion()"
    ><mat-icon svgIcon="arrow-left"></mat-icon>
      Предыдущий вопрос</button>
    <button class="question-buttons-primary" mat-stroked-button color="primary"
            *ngIf="!isLastQuestion; else endButton"
            (click)="goToNextQuestion()"
            [disabled]="!isElementsDisabled"
    >Следующий вопрос
      <mat-icon svgIcon="arrow-right"></mat-icon>
    </button>
  </div>
</ng-template>

<ng-template #endButton>
  <button class="question-buttons-primary" mat-stroked-button color="primary"
          [disabled]="!isElementsDisabled || isFinishButtonDisabled"
          (click)="finishQuiz()"
  >Завершить тестирование</button>
</ng-template>
