import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {
  variantSelector
} from '../reducers';
import {STATUSESENUM, VariantStateInterface} from '../interfaces/VariantStateInterface';
import {forkJoin, Observable, Subject} from 'rxjs';
import {map, switchMap, take, takeUntil} from 'rxjs/operators';
import {VariantInterface} from '../interfaces/VariantInterface';
import {transition, trigger, useAnimation} from '@angular/animations';
import {zoomIn} from 'ng-animate';
import {ViewportScroller} from '@angular/common';
import {QuestionInterface} from '../interfaces/QuestionInterface';
import {QuizService} from '../shared/services/quiz.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {QuestionService} from '../shared/services/question.service';
import {ScreenService} from '../shared/services/screen.service';
import {Timer} from '../timer/Timer';
import {VariantService} from '../shared/services/variant.service';

@Component({
  selector: 'lib-quiz-page',
  templateUrl: './quiz-page.component.html',
  styleUrls: ['./quiz-page.component.scss'],
  animations: [
    trigger('questionAnimation', [
      transition(':increment', useAnimation(zoomIn, {params: {timing: 0.2}
      })),
      transition(':decrement', useAnimation(zoomIn, {params: {timing: 0.2}})),
      transition('void => *', useAnimation(zoomIn, {params: {timing: 0.2}}))
    ]),
    trigger('questionsListAnimation', [
      transition('void => *', useAnimation(zoomIn, {params: {timing: 0.2}}))
    ])
  ]
})
export class QuizPageComponent implements OnInit, OnDestroy {
  @Input()
  variant: VariantInterface;
  @Input()
  questionsListNumber: number;
  @Input()
  currentQuestionNumber: number;
  @Input()
  variantStatus: STATUSESENUM;
  @Input()
  currentQuestion: QuestionInterface;

  changeCurrentQuestionSubject: Subject<void> = new Subject();
  isMobile: boolean;
  questionAnimationComplete = true;
  showQuestionsListToggle = false;
  timer: Timer;
  disableQuizActionsButtons = false;

  private unsubscribe$ = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    private readonly questionService: QuestionService,
    private readonly quizService: QuizService,
    private readonly screenService: ScreenService,
    private readonly store: Store <VariantStateInterface>,
    private readonly variantService: VariantService,
    private readonly viewportScroller: ViewportScroller,
  ) {
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.initScreenResize();
    this.initTimer();
    this.variantService.connectionRefused$
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap(() => this.store.pipe(
          select(variantSelector),
          map(state => state.variant))))
      .subscribe(variant => {
        if (this.variantStatus === this.STATUSENUM.started) {
          forkJoin(this.variantService.saveVariantAnswersOnConnectionRefusing(variant))
            .pipe(take(1))
            .subscribe(() => {
              this.disableQuizActionsButtons = false;
            });
        } else {
          this.disableQuizActionsButtons = false;
        }
      });
    this.variantService.connectionLost$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.disableQuizActionsButtons = true;
      });
    this.initQUiz(this.variantStatus);
  }

  finishQuiz() {
    this.changeCurrentQuestionSubject.next();
    this.quizService.finishVariant({
      variantId: this.variant.id });
    this.quizService.quizExpanded = true;
  }

  get changeCurrentQuestion$ () {
    return this.changeCurrentQuestionSubject.asObservable();
  }

  get quizExpanded () {
    return this.quizService.quizExpanded;
  }

  get STATUSENUM () {
    return STATUSESENUM;
  }

  goToNextQuestion($event) {
    this.changeCurrentQuestionSubject.next();
    this.quizService.selectQuestion(this.currentQuestionNumber + 1);
  }

  goToPreviousQuestion($event) {
    this.changeCurrentQuestionSubject.next();
    this.quizService.selectQuestion(this.currentQuestionNumber - 1);
  }

  goToSelectedQuestion($event: number) {
    this.changeCurrentQuestionSubject.next();
    this.quizService.selectQuestion($event);
  }

  goToSelectedQuestionFromQuestionsList($event: number) {
    this.goToSelectedQuestion($event);
    this.showQuestionsListToggle = false;
    if (this.isMobile && !(this.dialog.openDialogs && this.dialog.openDialogs.find(el => el.id === 'questionsListDialog'))) {
      this.dialog.getDialogById('questionsListDialog').close();
    }
  }

  questionAnimationStart() {
    this.viewportScroller.scrollToAnchor('questionAnchor');
    this.questionAnimationComplete = false;
  }

  questionAnimationEnd() {
    this.questionAnimationComplete = true;
    this.viewportScroller.scrollToAnchor('questionAnchor');
  }

  showFinishingConfirmDialog(): void {
    this.questionService.saveVariantAnswer();
    const finishingConfirmDialogRef = this.dialog.open(QuizFinishingConfiirmComponent, {
      id: 'quizFinishingDialog'
    });
    finishingConfirmDialogRef.afterClosed().pipe(take(1)).subscribe(isConfirmed => {
      if (isConfirmed) {
        this.timer.stopTimer();
      }
    });
  }

  showQuestionsList() {
    return this.variantStatus === STATUSESENUM.finished || this.showQuestionsListToggle && !this.isMobile;
  }

  startVariant() {
    if (!this.variant.id) {
      return;
    }
    this.quizService.startVariant(this.variant.id);
    this.timer.startTimer();
  }

  stopVariant() {
    this.timer.stopTimer();
  }

  toggleShowQuestions() {
    this.showQuestionsListToggle = !this.showQuestionsListToggle;
    if (this.isMobile) {
      this.showQuestionsListDialog();
    }
  }

  private initTimer() {
    this.timer = new Timer(this.variant.timeLeft);
    this.timer.getTimerStopped.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.finishQuiz();
    });
  }

  private showQuestionsListDialog (): void {
    const questionsListDialogRef = this.dialog.open(QuestionsListMobileDialogComponent,
      {id: 'questionsListDialog', data: {variant: this.variant, status: this.variantStatus} , autoFocus: false});
    questionsListDialogRef.afterClosed().pipe(take(1)).subscribe((isClosedByUser: boolean | number) => {
      if (isClosedByUser || isClosedByUser === 0) {
        if (typeof isClosedByUser === 'number') {
          this.goToSelectedQuestion(isClosedByUser);
        }
        this.showQuestionsListToggle = false;
      }
    });
  }

  private initQUiz(variantStatus: STATUSESENUM) {
    switch (variantStatus) {
      case STATUSESENUM.started: {
        this.timer.startTimer();
        break;
      }
      case STATUSESENUM.notStarted: {
        this.quizService.quizExpanded = true;
        break;
      }
      case STATUSESENUM.finished: {
        this.quizService.quizExpanded = true;
      }
    }
  }

  private initScreenResize() {
    this.isMobile = this.screenService.isMobileByScreenSize;
    this.screenService.isMobileByScreenSize$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(isMobile => {
        this.isMobile = isMobile;
        if (this.showQuestionsListToggle && !(this.variantStatus === STATUSESENUM.finished)) {
          if (this.isMobile && !(this.dialog.openDialogs && this.dialog.openDialogs.find(el => el.id === 'questionsListDialog'))) {
            this.showQuestionsListDialog();
          } else if (!this.isMobile && this.dialog.openDialogs && this.dialog.openDialogs.find(el => el.id === 'questionsListDialog')) {
            this.dialog.getDialogById('questionsListDialog').close();
          }
        }
      });
  }
}

@Component({
  selector: 'lib-quiz-escape-dialog',
  template: '<div class="dialogCloseButton">' +
    '<button mat-icon-button [autofocus]="false" color="primary" [mat-dialog-close]="true">' +
    '<mat-icon [ngStyle]="{height: \'12px\', width: \'12px\', \'line-height\':\'12px\' }" svgIcon="wrong"></mat-icon></button></div>' +
    '<mat-dialog-content><div class="escape-dialog-content">Пожалуйста, сосредоточьтесь на прохождении теста. ' +
    'Не переключайте вкладку, иначе тест будет завершен.</div>' +
    '<div class="ok-button-container"><button mat-flat-button color="primary" (click)="close()">Ок</button></div>' +
    '</mat-dialog-content>',
  styles: ['.dialogCloseButton{display: flex; width: 100%; justify-content: right; padding-bottom: 1rem}',
    '.escape-dialog-content {font-family: "Open Sans"; font-size: 1rem; font-weight: bolder}',
    '.ok-button-container {display: flex; width: 100%; padding-top: 2rem; justify-content: flex-end}']
})
export class QuizTabEscapeDalogComponent {
  constructor(public dialogRef: MatDialogRef<QuizTabEscapeDalogComponent>, @Inject(MAT_DIALOG_DATA) public data: number) { }
  close(confirmed?: boolean) {
    this.dialogRef.close(false);
  }
}

@Component({
  selector: 'lib-questions-list-mobile-dialog',
  template: '<div class="dialogCloseButton">' +
    '<button mat-icon-button color="primary" [mat-dialog-close]="true"><mat-icon svgIcon="wrong"></mat-icon></button>' +
    '</div>' +
    '<mat-dialog-content>' +
    '<lib-questions-list (goToQuestionEmitter)="goToSelectedQuestionFromQuestionsList($event)" [questions]="data.variant.questions" [quizStatus]="data.status">' +
    '</lib-questions-list></mat-dialog-content>',
  styles: ['.dialogCloseButton{display: flex; width: 100%; justify-content: right}']
})
export class QuestionsListMobileDialogComponent {
  constructor(public dialogRef: MatDialogRef<QuestionsListMobileDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {variant: VariantInterface; status: STATUSESENUM} ) {}
  goToSelectedQuestionFromQuestionsList($event: number) {
    this.dialogRef.close($event);
  }
}


@Component({
  selector: 'lib-quiz-finishing-confirm-dialog',
  template: '<h1 mat-dialog-title>Вы действительно хотите выйти из тестирования?</h1>' +
    '<mat-dialog-actions class="dialogActions">' +
    '<button mat-raised-button (click)="close(false)">Нет</button>' +
    '<button mat-raised-button color="primary" [mat-dialog-close]="true">Да</button>' +
    '</mat-dialog-actions>',
  styles: ['.dialogCloseButton{display: flex; width: 100%; justify-content: right}',
    '.dialogActions{justify-content: right}',
    '.mat-raised-button{width: 6rem!important; height: auto}']
})
export class QuizFinishingConfiirmComponent {
  constructor(
    public dialogRef: MatDialogRef<QuizFinishingConfiirmComponent>,
  ) {
  }
  close(confirmed?: boolean) {
    this.dialogRef.close(false);
  }
}
