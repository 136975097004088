import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {STATUSESENUM} from '../../../interfaces/VariantStateInterface';
import {Subject} from 'rxjs';
import {QuizService} from '../../services/quiz.service';
import {QuestionInterface} from '../../../interfaces/QuestionInterface';

@Component({
  selector: 'lib-quiz-navigation',
  templateUrl: './quiz-navigation.component.html',
  styleUrls: ['./quiz-navigation.component.scss']
})
export class QuizNavigationComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  quizStatus: STATUSESENUM;

  @Input()
  questions: QuestionInterface[];

  @Input()
  currentQuestion;

  @Output()
  goToQuestionEmitter = new EventEmitter();

  STATUSESENUM = STATUSESENUM;

  questionsStatus: {
    answered: boolean,
    count: number,
    right: boolean
  }[];

  private unsubscribe$ = new Subject<void>();

  constructor(private readonly quizService: QuizService) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    let i = 0;
    this.questionsStatus = this.questions.map(question => {
      return {
        count: i++,
        answered: !!question.answers.find(answer => answer.selectedByUser),
        right: question.isAnswerCorrect || null
      };
    });
  }

  goToQuestion(questIndex: number) {
    if (this.quizStatus === STATUSESENUM.finished) {
      return this.quizService.scrollToQuestion$.next(questIndex);
    }
    this.goToQuestionEmitter.emit(questIndex);
  }
}
