import { Injectable } from '@angular/core';
import {fromEvent, interval, Observable, of, Subject} from 'rxjs';
import {catchError, filter, map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {

  connectionLost$ = new Subject<void>();
  connectionRestored$ = new Subject<void> ();

  private _online$ = fromEvent(window, 'online').pipe(
    switchMap(() => interval(5000)
      .pipe(
        takeUntil(this.connectionRestored$),
        switchMap(() => this.pingServer()),
        filter(e => e),
        tap(() => this.connectionRestored$.next())
    ))
  );

  private _offline$ = fromEvent(window, 'offline').pipe(
    tap(() => this.connectionLost$.next()),
    switchMap(() => this.pingServer()),
    filter(e => !e)
  );

  constructor(
    private readonly http: HttpClient
  ) { }

  get offline$() {
    return this._offline$;
  }

  get online$() {
    return this._online$;
  }

  private pingServer(): Observable<boolean> {
    return this.http.get(environment.rnimuQUizConfig.url + '/rest/health').pipe(
      catchError(err => {
        if (err.error && err.error.text && (err.error.text === 'ok')) {
          return of(true); } else {
          return of(false); }
      }),
      map ((e: boolean) => e)
    );
  }
}
