import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChildren} from '@angular/core';
import {STATUSESENUM} from '../../../interfaces/VariantStateInterface';
import {QuizService} from '../../services/quiz.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {QuestionInterface} from '../../../interfaces/QuestionInterface';
import {MatRipple, RippleRef} from '@angular/material/core';
import {QuestionService} from '../../services/question.service';
@Component({
  selector: 'lib-questions-list',
  templateUrl: './questions-list.component.html',
  styleUrls: ['./questions-list.component.scss']
})
export class QuestionsListComponent implements OnInit, OnDestroy {

  private unsubscribe$ = new Subject<void>();

  constructor(
    private readonly quizService: QuizService
  ) { }

  @Input()
  questions: QuestionInterface[];

  @Input()
  quizStatus: STATUSESENUM;

  @Output()
  goToQuestionEmitter: EventEmitter<number> = new EventEmitter;

  @ViewChildren(MatRipple)
  ripples: MatRipple[];

  STATUSESENUM = STATUSESENUM;

  private rippleRef: RippleRef;

  fadeOutRipple() {
    if (this.rippleRef) {
      this.rippleRef.fadeOut();
      this.rippleRef = null;
    }
  }

  goToQuestion(i: number) {
    if (this.quizStatus === STATUSESENUM.started) {
      this.goToQuestionEmitter.emit(i);
    }
  }

  isQuestionAnsweredByUser = (question: QuestionInterface) => QuestionService.isAnsweredByUser(question);

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit() {
    this.quizService.scrollToQuestion$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((itemNumber: number) => this.scrollToQuestion(itemNumber));
  }

  private scrollToQuestion (i: number) {
    const scrollToElement = document.getElementById('item-' + i);
    if (scrollToElement) {
      if (this.rippleRef) {
        this.rippleRef.fadeOut();
        this.rippleRef = null;
      }
      scrollToElement.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'nearest'});
        this.rippleRef = this.ripples.filter((el, index) => index === i)[0].launch({
          centered: true,
          persistent: true
        });
    }
  }
}
