<div *ngIf="variantStatus !== STATUSENUM.loading">
  <div class="quiz" *ngIf="variantStatus">
    <div class="quiz-desktopMode" *ngIf="!isMobile">
      <ng-container *ngTemplateOutlet="desktopMode"></ng-container>
    </div>
    <div class="quiz-mobileMode" *ngIf="isMobile">
      <ng-container *ngTemplateOutlet="mobileMode"></ng-container>
    </div>
  </div>
</div>

<ng-template #desktopMode>
  <div class="quiz-info-row">
    <div class="quiz-info-col">
      <ng-container *ngTemplateOutlet="indicatorsTemplate"></ng-container>
    </div>
    <ng-container *ngTemplateOutlet="navigationTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="buttonsTemplate"></ng-container>
  </div>
</ng-template>

<ng-template #mobileMode>
  <div class="quiz-info-row">
    <div class="quiz-info-col">
      <ng-container *ngTemplateOutlet="indicatorsTemplate"></ng-container>
    </div>
    <ng-container *ngTemplateOutlet="navigationTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="buttonsTemplate"></ng-container>
  </div>
</ng-template>

<ng-template #buttonsTemplate>
  <div class="quiz-buttons">
    <mat-card-actions *ngIf="variantStatus === STATUSENUM.notStarted">
      <button class="quiz-buttons-stroked" mat-stroked-button  (click)="goBackToLearnIom()">
        <mat-icon svgIcon="link"></mat-icon>Вернуться</button>
      <button class="quiz-buttons-primary" mat-raised-button color="primary" [disabled]="disableQuizActionsButtons" (click)="startQuiz()">Начать тестирование</button>
    </mat-card-actions>
    <mat-card-actions *ngIf="variantStatus === STATUSENUM.started && !showQuestionsList()">
      <button  class="quiz-buttons-stroked quiz-buttons-questionListDesktop" mat-stroked-button (click)="toggleShowQuestions()">
        <mat-icon svgIcon="bullet-list"></mat-icon>Список вопросов</button>
      <button  class="quiz-buttons-stroked quiz-buttons-questionListMobile" mat-stroked-button (click)="toggleShowQuestions()">
        <mat-icon svgIcon="bullet-list"></mat-icon>Список вопросов</button>
      <button  class="quiz-buttons-primary" mat-raised-button color="primary" [disabled]="disableQuizActionsButtons" (click)="showFinishingConfirmDialog()">Завершить тестирование</button>
    </mat-card-actions>
    <mat-card-actions *ngIf="variantStatus === STATUSENUM.started && showQuestionsList()">
      <button mat-raised-button color="primary" class="quiz-buttons-primary quiz-buttons-questionListDesktop" (click)="toggleShowQuestions()">
        Вернуться к тестированию</button>
      <button mat-raised-button color="primary"  class="quiz-buttons-primary quiz-buttons-questionListMobile" (click)="toggleShowQuestions()">
        Вернуться к тестированию</button>
      <button mat-stroked-button  class="quiz-buttons-stroked" color="primary" [disabled]="disableQuizActionsButtons" (click)="showFinishingConfirmDialog()">Завершить тестирование</button>
    </mat-card-actions>
    <mat-card-actions *ngIf="variantStatus === STATUSENUM.finished">
      <button class="quiz-buttons-stroked" color="primary" mat-raised-button  (click)="goBackToLearnIom()">
        <mat-icon svgIcon="link"></mat-icon>Вернуться</button>
    </mat-card-actions>
  </div>
</ng-template>

<ng-template #navigationTemplate>
  <div class="quiz-info-navigation">
    <div class="mat-grid-tile-navigation" *ngIf="variantStatus === STATUSENUM.started || variantStatus === STATUSENUM.finished ">
      <lib-quiz-navigation
        [quizStatus]="variantStatus"
        [questions]="variant.questions"
        [currentQuestion]="currentQuestionNumber"
        (goToQuestionEmitter)="goToSelectedQuestion($event)"
      ></lib-quiz-navigation>
    </div>
  </div>
</ng-template>

<ng-template #indicatorsTemplate>
  <div class="quiz-info-col-indicators" *ngIf="variantStatus !== STATUSENUM.finished">
          <span class="quiz-info-col-indicators-item">
            <lib-status-indicator
              [textLabel]="'Количество\nвопросов'"
              [textValue]="'' + questionsListNumber"
            ></lib-status-indicator>
          </span>
    <span class="quiz-info-col-indicators-item">
            <lib-status-indicator
              [textLabel]="'Время\nтестирования'"
              [textValue]="(variant.examTime | date:'m':'UTC') + ' мин'"
            ></lib-status-indicator>
          </span>
    <span class="quiz-info-col-indicators-item">
            <lib-status-indicator
              [textLabel]="'\nCтатус\n'"
              [textValue]="variantStatus === STATUSENUM.notStarted?'Не начат':
                           variantStatus === STATUSENUM.started?'В процессе':''"
            ></lib-status-indicator>
    </span>
  </div>
  <div class="quiz-info-col-indicators" *ngIf="variantStatus === STATUSENUM.finished">
    <span class="quiz-info-col-indicators-item">
            <lib-status-indicator
              [textLabel]="'Правильных\nответов'"
              [textValue]=" variant.positiveCount + ' из ' + questionsListNumber +
              ' (' + variant.correctPercent + '%)'"
            ></lib-status-indicator>
    </span>
    <span class="quiz-info-col-indicators-item">
            <lib-status-indicator
              [textLabel]="'\nОценка\n'"
              [textValue]="'' + variant.mark"
            ></lib-status-indicator>
    </span>
    <span class="quiz-info-col-indicators-item">
            <lib-status-indicator
              [textLabel]="'Время\nтестирования'"
              [textValue]="variant.examLength +
              ' из ' + (variant.examTime | date:'m':'UTC') + ' мин'"
            ></lib-status-indicator>
          </span>
    <span class="quiz-info-col-indicators-item">
            <lib-status-indicator
              [textLabel]="'\nСтатус\n'"
              [textValue]="'Завершен'"
              [mark]="'warn'"
            ></lib-status-indicator>
    </span>
  </div>
</ng-template>
