import {Inject, Injectable, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {v4 as uuidv4} from 'uuid';
import {Observable} from 'rxjs';
import {delay, map, mergeMap, take, tap} from 'rxjs/operators';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {Router} from '@angular/router';

export interface TokenDataDto {
  access_token: string;
  token_type: string;
  refresh_token: string;
  expires_in: number;
  scope: string;
}

export interface LoginForm {
  username: string;
  password: string;
}

@Injectable()
export class SecurityService implements OnInit {
  get magicImageUrl$(): Observable<SafeUrl> {
    return this._magicImageUrl$;
  }
  private _magicImageUrl$: Observable<SafeUrl>;

  get authHeader(): string {
    if (!this.accessToken) {
      return null;
    }
    return 'Bearer ' + this.accessToken;
  }

  get isLoggedIn(): boolean {
    return !!this.accessToken;
  }

  get accessToken(): string {
    return this._accessToken;
  }
  private _accessToken: string;

  private _sessionId;

  constructor(
    private readonly _sanitizer: DomSanitizer,
    @Inject('env') private readonly env,
    private readonly http: HttpClient,
    private readonly router: Router
  ) {
    this.setSessionId();
    this._accessToken = sessionStorage.getItem('access_token');
    this._magicImageUrl$ = this.getMagicImageUrl();
  }

  getMagicImageUrl (): Observable<SafeUrl> {
    return this.http.get(
      `${this.env.url}/rest/mellophone/gif-url?sesid=${this._sessionId}`, {responseType: 'text'}
    ).pipe(map(res => {
      return this._sanitizer.bypassSecurityTrustUrl(res.replace(/\"/g, ''));
    }));
  }

  recieveToken (): Observable<void> {
    return this.http.get(`${this.env.url}/rest/mellophone/token?sesid=${this._sessionId}`)
      .pipe(
        map((res: TokenDataDto) => {
          sessionStorage.setItem('access-token', this._accessToken = res.access_token);
        }));
  }

  loginByPass(data: LoginForm) {
    const headers: HttpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded');

    const body: HttpParams = new HttpParams()
      .set('username', data.username)
      .set('password', data.password);

    return this.http.post(`${this.env.url}/rest/mellophone/login?sesid=${this._sessionId}`,
      body, {
        headers: headers
      }).pipe(
        tap((res: TokenDataDto) => {
          sessionStorage.setItem('access-token', this._accessToken = res.access_token);
        }));
  }

  logout (): void {
    this.http.get(`${this.env.url}/rest/mellophone/logout?sesid=${this._sessionId}`)
      .pipe(take(1))
      .subscribe(() => {
        sessionStorage.clear();
        this._accessToken = null;
        this._sessionId = null;
        this.router.navigate(['/login']);
      });
  }

  ngOnInit(): void {

  }

  setSessionId (): void {
    const savedSession = sessionStorage.getItem('sesid');
    if (savedSession) {
      this._sessionId = savedSession;
      return;
    }
    sessionStorage.setItem('sesid', this._sessionId = uuidv4());
  }

  updateMagicImage <T> (obs?: Observable<T>): Observable<T> {
    this._magicImageUrl$ = this.getMagicImageUrl();
    if (!obs) { return; }
    return new Observable((observer) => {
      setTimeout(() => observer.next(), 100);
    }).pipe(mergeMap(() => obs));
  }
}
