import {createAction, props} from '@ngrx/store';
import {AnswerInterface} from '../interfaces/AnswerInterface';
import {VariantInterface} from '../interfaces/VariantInterface';

export const loadVariant = createAction('[Variant Page] load variant', props<{ variantId: string }>());

export const receiveVariant = createAction('[VariantApi] receive variant', props<{ variant: VariantInterface }>());

export const startVariant = createAction('[Variant] start variant', props<{ variantId: string}>());

export const setVariantStarted = createAction('[VariantApi] set variant started', props<{startTime: Date}>());

export const finishVariant = createAction('[Variant] finish variant', props<{ variantId: string }>());

export const finishVariantQuery = createAction('[Effects] finish variant query', props<{ variantId: string}>());

export const goToSelectedQuestion = createAction('[Quiz] go to selected question', props<{selectedQuestion: number}>());

export const selectQuestion = createAction('[Quiz] select question', props<{ selectedQuestion: number }>());

export const setAnswer = createAction('[Question] set answer', props<{ questionId: string, answers: AnswerInterface[] }>());

export const saveVariantAnswers = createAction('[Question saveVariantAnswers]');

export const setQuestionSyncedStatus = createAction('[VariantApi] set synced status', props<{questionId: string}>());

export const setError = createAction('[Quiz] set error', props<{errorMsg: string}>());
