import {Component, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';


import {STATUSESENUM, VariantStateInterface} from '../../../interfaces/VariantStateInterface';
import {Timer} from '../../../timer/Timer';
import {Store} from '@ngrx/store';
import {ScreenService} from '../../services/screen.service';
import {QuestionService} from '../../services/question.service';
import {VariantService} from '../../services/variant.service';
import {QuizService} from '../../services/quiz.service';
import {VariantInterface} from '../../../interfaces/VariantInterface';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'lib-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
})
export class QuizComponent implements OnInit, OnDestroy {
  @Input()
  currentQuestionNumber: number;
  @Input()
  disableQuizActionsButtons: boolean;
  @Input()
  variant: VariantInterface;
  @Input()
  variantStatus: STATUSESENUM;
  @Input()
  questionsListNumber: number;
  @Input()
  changeCurrentQuestionSubject: Subject<void>;
  @Input()
  isMobile: boolean;
  @Input()
  showQuestionsListToggle: boolean;
  @Input()
  timer: Timer;
  @Output()
  toggleShowQuestionsEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  showFinishingConfirmDialogEmitter: EventEmitter<void> = new EventEmitter<void>();

  STATUSENUM = STATUSESENUM;


  private unsubscribe$ = new Subject<void>();

  private escapeTabTimes: number;

  constructor(
    @Inject('env') private env,
    private readonly questionService: QuestionService,
    private readonly quizService: QuizService,
    private readonly screenService: ScreenService,
    private readonly store: Store<VariantStateInterface>,
    private readonly variantService: VariantService,
  ) { }

  get quizExpanded () {
    return this.quizService.quizExpanded;
  }

  set quizExpanded (val) {
    this.quizService.quizExpanded = val;
  }

  showQuestionsList() {
    return this.variantStatus === STATUSESENUM.finished || this.showQuestionsListToggle && !this.isMobile;
  }

  goToSelectedQuestion($event: number) {
    this.changeCurrentQuestionSubject.next();
    this.quizService.selectQuestion($event);
  }

  goBackToLearnIom() {
    if (this.variant.backUrl) {
      window.location.href = this.variant.backUrl;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.escapeTabTimes = this.env.escapeTabTimes;
  }

  startQuiz() {
    this.quizService.startVariant(this.variant.id);
    this.quizService.quizExpanded = false;
    this.timer.startTimer();
  }

  @HostListener('document:visibilitychange', ['$event'])
  private updateTimerWhenReturnToTestTab () {
    if (!document.hidden) {
      this.variantService.getServerTime().pipe(
        tap((serverTime: Date) =>
          this.timer.timeLeft = QuizService.currentTimeLeft(this.variant.startTime, this.variant.endTime, serverTime)));
    }
  }

//  Приблуда для окончания тестирования в случае, если пользак переключает окна.
/*  @HostListener('window:blur', ['$event'])
  private finishQuizOnChangeBrowserTab (event: any) {
    if (this.variantState.status !== this.STATUSENUM.started) {
      return;
    }
    if (this.escapeTabTimes <= 0) {
      this.timer.stopTimer();
      return;
    }
    if (this.dialog.getDialogById('quizTabEscapeDialog')) {
      return;
    }
    this.dialog.open(QuizTabEscapeDalogComponent, {id: 'quizTabEscapeDialog', data: this.escapeTabTimes,
      maxWidth: '30rem', autoFocus: false})
      .afterClosed().pipe(take(1)).subscribe(() => { this.escapeTabTimes--; });
  }*/

  toggleShowQuestions() {
    this.toggleShowQuestionsEmitter.emit();
  }

  showFinishingConfirmDialog() {
    this.showFinishingConfirmDialogEmitter.emit();
  }
}
