import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {Variant} from '../classes/Variant';
import {map} from 'rxjs/operators';
import {VariantInfoDto} from '../../../projects/rnimu-quiz/src/lib/interfaces/dto/VariantInfoDto';


@Injectable({
  providedIn: 'root'
})
export class VariantService {

  get variantTitle() {
    return this._variantTitle;
  }
  set variantTitle(variantTitle) {
    this._variantTitle = variantTitle;
  }
  private _variantTitle: string;

  constructor(
    private readonly httpClient: HttpClient
  ) { }

  getVariantsOfCurrentUser(): Observable<Variant[]> {
    return this.httpClient
      .get(environment.rnimuQUizConfig.url + '/rest/quiz/variants')
      .pipe(
        map((variantsOfCurrentUser: VariantInfoDto[]) => variantsOfCurrentUser
          .map(variantOfCurrentUser => new Variant(variantOfCurrentUser))));
  }
}
