import {Action, createFeatureSelector, createReducer, createSelector, MemoizedSelector, on} from '@ngrx/store';
import * as VariantActions from '../actions/variant.actions';
import {STATUSESENUM, VariantStateInterface} from '../interfaces/VariantStateInterface';
import {QuestionInterface} from '../interfaces/QuestionInterface';
import {VariantInterface} from '../interfaces/VariantInterface';

const initState: VariantStateInterface = {
  status: STATUSESENUM.notLoaded,
  currentQuestion: null,
  variant: null
};

const variantReducer = createReducer(initState,
  on(VariantActions.loadVariant, (state, { variantId }) => {
    return {...state, status: STATUSESENUM.loading};
  }),
  on(VariantActions.receiveVariant, (state, { variant }) => {
    const status: STATUSESENUM = (variant.startTime && variant.finishTime) ?
      STATUSESENUM.finished : variant.startTime ? STATUSESENUM.started : STATUSESENUM.notStarted;
    return {...state, variant, status: status, currentQuestion: 0};
  }),
  on(VariantActions.setVariantStarted, (state, { startTime }) => {
    return {...state, status: STATUSESENUM.started, variant: {
      ...state.variant,
        startTime: startTime,
        endTime: new Date(startTime.getTime() + state.variant.examTime)
      }};
  }),
  on(VariantActions.selectQuestion, (state, { selectedQuestion }) => {
    return {...state, currentQuestion: selectedQuestion};
  }),
  on(VariantActions.setAnswer, (state, {questionId, answers }) => {
    return {...state, variant: {...state.variant, questions: state.variant.questions
          .map(q => (q.id === questionId) ?
            {
              ...q,
              haveToSync: true,
              answers: q.answers.map(a => ({
                ...a,
                selectedByUser: answers.find(answer => a.id === answer.id).selectedByUser
              }))} :
            {...q})}};
  }),
  on(VariantActions.finishVariant, (state) => {
    return {...state, status: STATUSESENUM.loading};
  }),
  on(VariantActions.setQuestionSyncedStatus, (state, {questionId}) => {
    return {...state, variant: {...state.variant, questions: state.variant.questions.map(
      q => (q.id === questionId) ? {...q, haveToSync: false} : {...q})}};
  }),
  on(VariantActions.setError, (state, {errorMsg}) => {
    return {
      ...state,
      status: STATUSESENUM.error,
      errorMsg: errorMsg
    };
  })
);

export function reducer(state: VariantStateInterface, action: Action) {
  return variantReducer(state, action);
}

export const featureVariantStateSelector = createFeatureSelector<VariantStateInterface>('variantState');

export const variantSelector = createSelector(
  featureVariantStateSelector, (state: VariantStateInterface) => state
);

export const variantBackUrlSelector: MemoizedSelector<VariantStateInterface, string> = createSelector(
  featureVariantStateSelector, (state: VariantStateInterface) => state && state.variant && state.variant.backUrl
);
createSelector (
  featureVariantStateSelector, (state: VariantStateInterface) => state && state.variant && state.variant.correctPercent
);
export const variantCurrentQuestionNumberSelector: MemoizedSelector<VariantStateInterface, number> = createSelector(
  featureVariantStateSelector, (state: VariantStateInterface) => state.currentQuestion
);

export const variantCurrentQuestionSelector: MemoizedSelector<VariantStateInterface, QuestionInterface> = createSelector(
  featureVariantStateSelector, (state: VariantStateInterface) => state && state.variant && state.variant.questions
    && state.variant.questions[state.currentQuestion]
);

export const variantExamTimeSelector: MemoizedSelector<VariantStateInterface, number> = createSelector(
  featureVariantStateSelector, (state: VariantStateInterface) => state && state.variant.examTime
);
createSelector(
  featureVariantStateSelector, (state: VariantStateInterface) => {
    const variant: VariantInterface = state && state.variant;
    const res = (variant.finishTime.getTime() - variant.startTime.getTime());
    return res < variant.examTime ? res : variant.examTime;
  }
);
createSelector(
  featureVariantStateSelector, (state: VariantStateInterface) => state && state.variant && state.variant.mark
);
export const variantPositiveCountSelector: MemoizedSelector<VariantStateInterface, number> = createSelector(
  featureVariantStateSelector, (state: VariantStateInterface) => state && state.variant && state.variant.positiveCount
);
createSelector(
  featureVariantStateSelector, (state: VariantStateInterface) => state && state.variant && state.variant.questions
);
export const variantQuestionsNumberSelector: MemoizedSelector<VariantStateInterface, number> = createSelector(
  featureVariantStateSelector, (state: VariantStateInterface) => state && state.variant && state.variant.questions
    && state.variant.questions.length
);

export const variantStatusSelector: MemoizedSelector<VariantStateInterface, STATUSESENUM> = createSelector(
  featureVariantStateSelector, (state: VariantStateInterface) => state.status
);
createSelector (
  featureVariantStateSelector, (state: VariantStateInterface) => state && state.variant && state.variant.title
);
