<div class="quizzes-list" *ngIf="isLoggedIn">
  <mat-card class="quizzes-list-card">
    <mat-card-title>Список вариантов</mat-card-title>
    <mat-card-content>
      <table mat-table [dataSource]="variantsList$ | async" class="quizzes-list-table">
        <ng-container matColumnDef="number">
          <th mat-header-cell *matHeaderCellDef>No.</th>
          <td mat-cell *matCellDef="let variant; let i = index">{{i + 1}}</td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Номер и статус варианта</th>
          <td mat-cell *matCellDef="let variant">{{variant.name}}</td>
        </ng-container>
        <ng-container matColumnDef="id">
          <th class="quizzes-list__column-action" mat-header-cell *matHeaderCellDef>Действие</th>
          <td class="quizzes-list__column-action" mat-cell *matCellDef="let variant">
            <button mat-raised-button color="primary" class="quizzes-list-button"
                    [routerLink]="['/quiz-wrapper/' + variant.id]"
                    [queryParams]="{
                       variantId: variant.id
                    }"
                    [queryParamsHandling]="'preserve'"
            >Перейти</button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </mat-card-content>
  </mat-card>
</div>

<ng-template #loader>
  <mat-progress-spinner></mat-progress-spinner>
</ng-template>
