import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Timer} from '../../../timer/Timer';
import {QuestionService} from '../../services/question.service';
import {QuestionInterface} from '../../../interfaces/QuestionInterface';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {AnswerInterface} from '../../../interfaces/AnswerInterface';

@Component({
  selector: 'lib-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnDestroy {

  constructor(
    readonly questionService: QuestionService
  ) { }

  @Input()
  currentQuestion: number;

  @Input()
  timer: Timer;

  @Input()
  numberOfQuestions: number;

  @Input()
  question: QuestionInterface;

  @Input()
  isFirstQuestion: boolean;

  @Input()
  isLastQuestion: boolean;

  @Input()
  isElementsDisabled: boolean;

  @Input()
  isFinishButtonDisabled: boolean;

  @Input()
  questionNavigationEvent$: Observable<void>;

  @Output()
  goToNextQuestionEmitter = new EventEmitter();

  @Output()
  goToPreviousQuestionEmitter = new EventEmitter();

  @Output()
  setSelectedAnswerEmitter = new EventEmitter();

  @Output()
  finishQuizEmitter = new EventEmitter();

  private unsubscribe$ = new Subject<void>();

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setSelectedSingleAnswer(answer: AnswerInterface) {
    this.questionService.setAnswersInStore(this.question.id, this.question.answers.map(a => ({...a, selectedByUser: a.id === answer.id})));
  }

  setSelectedMultiAnswer(answer: AnswerInterface, $event: MatCheckboxChange) {
    this.questionService.setAnswersInStore(this.question.id, this.question.answers
      .map(a => a.id === answer.id ? ({...a, selectedByUser: $event.checked}) : a));
  }

  goToNextQuestion() {
    if (this.isLastQuestion) { return; }
    this.goToNextQuestionEmitter.emit();
  }

  goToPreviousQuestion() {
    this.goToPreviousQuestionEmitter.emit();
  }

  finishQuiz() {
    this.finishQuizEmitter.emit();
  }
}
