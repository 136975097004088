import {HostListener, Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';

@Injectable()
export class ScreenService {
  private screenWidth: number;
  private isMobileByScreenSizeSubject: Subject<boolean> = new Subject();

  get isMobileByScreenSize$ () {
    return this.isMobileByScreenSizeSubject.pipe(
      distinctUntilChanged((prev, cur) => prev === cur)
    );
  }

  get isMobileByScreenSize () {
    return this.screenWidth < 1334;
  }

  set setScreenWidth(screenWidth: number) {
    this.screenWidth = screenWidth;
    this.isMobileByScreenSizeSubject.next(this.screenWidth < 1334);
  }
}
