import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {LoginForm, SecurityService} from '../services/security.service';
import {ActivatedRoute, Router} from '@angular/router';
import {catchError, take, timeout} from 'rxjs/operators';
import {throwError} from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm = this.formBuilder.group({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
  });

  get password() { return this.loginForm.get('password'); }
  get username() { return this.loginForm.get('username'); }

  private cb: string;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly securityService: SecurityService
  ) { }

  handleSubmit($event: SubmitEvent) {
    $event.preventDefault();
    const data: LoginForm = this.loginForm.value;
    if (!data.password || !data.username) {
      this.loginForm.setErrors({'loginEmptyFields': 'Заполните обязательные поля'});
      return;
    }
    this.securityService.updateMagicImage(this.securityService.loginByPass(data))
      .pipe(
        take(1),
        catchError(err => {
          this.loginForm.reset();
          this.loginForm.setErrors({'loginRequestError': err?.error?.message || err?.statusText});
          return throwError(err);
        })
      )
      .subscribe(() => {
        this.securityService.updateMagicImage();
        this.router.navigate([decodeURI(this.cb)]).then();
      });
  }

  ngOnInit(): void {
    this.route.paramMap.
    pipe(take(1))
      .subscribe(params => {
        if (!params) { return; }
        this.cb = params.get('cb');
      });
  }

}
