<div class="about">
  <mat-card class="about-card">
    <mat-card-title>Описание</mat-card-title>
    <mat-card-content>
      <div>
        <span>Версия сборки: </span>
        <span>1.3.3-build2</span>
      </div>
    </mat-card-content>
  </mat-card>
</div>

