import {VariantInfoDto} from '../../../projects/rnimu-quiz/src/lib/interfaces/dto/VariantInfoDto';

export class Variant {
  id: string;
  name: string;
  createDate: Date;
  endDate: Date;
  finishDate: Date;

  constructor(variantServerResponse: VariantInfoDto) {
    if (!variantServerResponse) {
      throw new Error('there is no variantServerResponse');
    }
    this.id = variantServerResponse.id;
    this.name = variantServerResponse.code;
    this.createDate = new Date(variantServerResponse.startTime);
    this.endDate = new Date(variantServerResponse.endTime);
    this.finishDate = new Date(variantServerResponse.finishTime);
  }

}
