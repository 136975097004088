<ng-container *ngIf="variantStatus$ | async as variantStatus; else loader">
  <ng-container *ngIf="variant$ | async as variant">
    <lib-quiz-page
      *ngIf="isLoading(variantStatus); else loader"
      [variant]="variant"
      [currentQuestion]="currentQuestion$ | async"
      [questionsListNumber]="questionsListNumber$ | async"
      [currentQuestionNumber]="currentQuestionNumber$ | async"
      [variantStatus]="variantStatus"
    ></lib-quiz-page>
    <div *ngIf="isError(variantStatus)" class="error-card">
      <div class="error-card__title">Ошибка при загрузке...</div>
      <button class="error-card__button" color="primary" type="button" (click)="handleErrorButtonClick(variant.backUrl)" mat-flat-button>
        Перейти назад к обучению
      </button>
    </div>
  </ng-container>
</ng-container>

<ng-template #loader>
  <div class="loader">
    <mat-spinner
      label="Загрузка..."
    ></mat-spinner>
  </div>
</ng-template>
