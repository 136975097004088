import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {tap} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {AccessibleModeService} from '../services/accessible-mode.service';
import {ConnectionService} from '../services/connection.service';
import {VariantService} from '../services/variant.service';

@Component({
  selector: 'app-quiz-wrapper',
  templateUrl: './quiz-wrapper.component.html',
  styleUrls: ['./quiz-wrapper.component.css']
})
export class QuizWrapperComponent implements OnInit, OnDestroy {

  variantId: string;
  private activatedRouteSub: Subscription;
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly accessibleModeService: AccessibleModeService,
    private readonly connectionService: ConnectionService,
    private readonly variantService: VariantService
  ) { }

  get getAccessibleModeState () {
    return this.accessibleModeService.getAccessibleModeState;
  }

  get getConnectionRefused(): Subject<void> {
    return this.connectionService.connectionRestored$;
  }

  get getConnectionLost(): Subject<void> {
    return this.connectionService.connectionLost$;
  }

  ngOnInit(): void {
    this.activatedRouteSub = this.activatedRoute.params
      .pipe(
        tap (params => {
          this.variantId = params.variantId;
        })).subscribe();
  }

  ngOnDestroy(): void {
    this.activatedRouteSub.unsubscribe();
  }

  setVariantTitle($event: string) {
    this.variantService.variantTitle = $event;
  }
}
