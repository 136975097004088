import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccessibleModeService {
  private accessibleModeState: boolean;
  constructor() { }

  get getAccessibleModeState () {
    return this.accessibleModeState;
  }

  accessibleModeToggle (setValue?: boolean) {
    if (typeof setValue !== 'boolean') {
      return this.accessibleModeState = !this.accessibleModeState;
    } else {
      return this.accessibleModeState = setValue;
    }
  }
}
