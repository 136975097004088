import { Injectable } from '@angular/core';
import {AnswerInterface} from '../../interfaces/AnswerInterface';
import {Store} from '@ngrx/store';
import * as VariantActons from '../../actions/variant.actions';
import {VariantStateInterface} from '../../interfaces/VariantStateInterface';
import {QuestionInterface} from '../../interfaces/QuestionInterface';
import {AnswerDto} from '../../interfaces/dto/AnswerDto';
import {QuestionDto} from '../../interfaces/dto/QuestionDto';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  constructor(
    private readonly store: Store<VariantStateInterface>
  ) { }

  static isAnsweredByUser(question: QuestionInterface) {
    return question.answers.some(a => a.selectedByUser);
  }

  getValuesFromAnswerServerResponse (answerDto: AnswerDto): AnswerInterface {
    return {
      id: answerDto.id,
      content: answerDto.content,
      stringContent: answerDto.content.replace(/<!--(.|\n)*?-->|<(.|\n)*?>/g, ''),
      selectedByUser: answerDto.selected,
    };
  }

  getValuesFromQuestionServerResponse(questionDto: QuestionDto): QuestionInterface {
    return {
      id: questionDto.id,
      content: questionDto.content,
      stringContent: questionDto.content.replace(/<!--(.|\n)*?-->|<(.|\n)*?>/g, ''),
      type: questionDto.type,
      orderNumber: questionDto.order,
      isAnswerCorrect: questionDto.correct,
      imgUrls: questionDto.imgUrls,
      answers: questionDto.answers.map(answerSR =>
        this.getValuesFromAnswerServerResponse(answerSR)
      )
    };
  }

  saveVariantAnswer(): void {
    this.store.dispatch(VariantActons.saveVariantAnswers());
  }

  setAnswersInStore (questionId: string, answers: AnswerInterface[]) {
    this.store.dispatch(VariantActons.setAnswer({questionId: questionId, answers: answers}));
  }
}
