import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {catchError} from 'rxjs/operators';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isAssetsRequest(req)) {
      req.headers.set('Expires', '0');
    }
    return next.handle(req)
      .pipe(catchError((err: HttpErrorResponse) => {
        return throwError(err); }));
  }

  private isAssetsRequest(req: HttpRequest<any>): boolean {
    // Firefox fires status:0 when load assets from cache
    return req.url.includes(environment.rnimuQUizConfig.webUrl + 'assets/');
  }
}
