import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AccessibleModeService} from './services/accessible-mode.service';
import {SecurityService} from './services/security.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ConnectionService} from './services/connection.service';
import {takeUntil} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {initSentry} from './sentry/sentryConfig';
import {SafeUrl, Title} from '@angular/platform-browser';
import {VariantService} from './services/variant.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent implements OnInit, OnDestroy {

  get magicImageUrl$ (): Observable<SafeUrl> {
    return this.securityService.magicImageUrl$;
  }

  get variantTitle (): string {
    return this.variantService.variantTitle;
  }

  openedInOtherTab = false;
  private unsubscribe$ = new Subject <void>();

  constructor(
    private readonly _snackBar: MatSnackBar,
    private readonly accessibleModeService: AccessibleModeService,
    private readonly connectionService: ConnectionService,
    private readonly router: Router,
    private readonly securityService: SecurityService,
    private readonly titleService: Title,
    private readonly variantService: VariantService,
  ) {
    this.titleService.setTitle('Тестирование НМИФО');
    this.connectionService.offline$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this._snackBar.open('Отсутствует связь с сервером. Проверьте соединение с интернетом.', '', {
          verticalPosition: 'top'
        });
    });
    this.connectionService.online$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this._snackBar.dismiss();
      });
  }

  get isLoggedIn () {
    return this.securityService.isLoggedIn;
  }

  ngOnInit(): void {
    initSentry();
    this.accessibleModeService.accessibleModeToggle(false);
    localStorage.openpages = Date.now();
  }

  ngOnDestroy(): void {
   this.unsubscribe$.next();
   this.unsubscribe$.complete();
  }

  @HostListener('window:storage', ['$event'])
  detectionOfOtherTabs(e) {
    if (e.key === 'openpages') {
      this.openedInOtherTab = false;
      localStorage.page_available = Date.now();
    }
    if (e.key === 'page_available') {
      this.openedInOtherTab = true;
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  onCloseTab(e) {
    localStorage.openpages = Date.now();
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: FocusEvent): void {
    // this.securityService.pingIdpSession();
  }

  logout() {
    return this.securityService.logout();
  }
}

