<mat-toolbar class="toolbar" *ngIf="isLoggedIn">
  <span class="rnimu-logo">
      <mat-icon class="rnimu-logo-icon" svgIcon="rnimu-logo"></mat-icon>
      <span class="rnimu-logo-text">Платформа оналйн-обучения<br/> РНИМУ им. Н.И. Пирогова</span>
  </span>
  <span class="rnimu-variantTitle">{{this.variantTitle}}</span>
  <button (click)="logout()"mat-icon-button color="primary">
    <mat-icon svgIcon="exit"></mat-icon>
  </button>
</mat-toolbar>

<ng-container *ngIf="magicImageUrl$ | async as magicImageUrl">
  <img hidden="hidden" style="width: 200px" [src]="magicImageUrl" alt="magicImage">
</ng-container>

<ng-container class="route" *ngIf="!openedInOtherTab; else simpleMessage">
  <router-outlet class="router-outlet"></router-outlet>
</ng-container>

<ng-template #simpleMessage>
  <app-simple-message *ngIf="openedInOtherTab" class="simple-message"
                      [closeButtonEnabled]="false"
                      [nextButtonEnabled]="false"
                      [message]="'Приложение тестирования открыто более чем в одной вкладке. Проверьте вкладки.'"
  ></app-simple-message>
</ng-template>
