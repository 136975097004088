import {Observable, Subject, timer} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

export class Timer {
  private timer$ = timer(1000, 1000);
  private stopTimer$ = new Subject<void>();
  private _timeLeft: number;

  constructor(timeLeft: number) {
    this._timeLeft = timeLeft;
  }

  get timeLeft(): number { return this._timeLeft; }

  set timeLeft(timeLeft: number) {
    this._timeLeft = timeLeft;
  }

  get getTimerStopped(): Observable<void> {
    return  this.stopTimer$.asObservable();
  }

  startTimer(): void {
    this.timer$
      .pipe(takeUntil(this.stopTimer$))
      .subscribe(() => {
        this.timerDecrement();
      });
  }

  stopTimer(): void {
    this.stopTimer$.next();
  }

  private timerDecrement() {
    if (this._timeLeft <= 0) {
      this.stopTimer();
      return;
    }
    this._timeLeft -= 1000;
  }
}


