import {ErrorHandler, Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import * as Sentry from '@sentry/browser';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  constructor(
    private readonly _snackBar: MatSnackBar,
    private readonly _router: Router
  ) {
  }

  private static isSilentError (error: Error | HttpErrorResponse) {
    if (error instanceof HttpErrorResponse &&
      (error.status === 401 || error.status === 422 || error.status === 400 || error.status === 307)) {
      return true;
    }
    return error instanceof HttpErrorResponse &&
      error?.url?.includes('qt_QtService/saveVariantAnswers');
  }

  handleError(error: Error | HttpErrorResponse | any): void {
    console.dir(error);
    if (!AppErrorHandler.isSilentError(error)) {
      this._snackBar.open(error.error?.message || error?.statusText || error?.message, 'Ок', {panelClass: 'error-snack-bar'});
      if (error.error?.status === 404) {
        this._router.navigate(['/']).then();
      }
      Sentry.captureException(error);
    }
  }
}
