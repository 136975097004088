import {VariantInterface} from './VariantInterface';

export enum STATUSESENUM {
  'notLoaded',
  'loading',
  'notStarted',
  'started',
  'finished',
  'error'
}

export interface VariantStateInterface {
  status: STATUSESENUM;
  currentQuestion: number;
  variant: VariantInterface;
  errorMsg?: string;
}
