import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import {VariantStateInterface} from '../../interfaces/VariantStateInterface';
import {AnswerInterface} from '../../interfaces/AnswerInterface';
import * as VariantActons from '../../actions/variant.actions';
import * as VariantActions from '../../actions/variant.actions';

@Injectable({
  providedIn: 'root'
})
export class QuizService {
  quizExpanded = false;

  private _scrollToQuestion$ = new Subject <number> ();

  constructor(
    private readonly store: Store<VariantStateInterface>
  ) { }

  static currentTimeLeft (startTime, endTime, now) {
    let currentTImeLeft = 0;
    if (startTime && endTime) {
      currentTImeLeft = (endTime.getTime() - now.getTime());
    }
    return currentTImeLeft;
  }

  finishVariant (currentQuestionAnswers: {variantId}): void {
    this.store.dispatch(VariantActions.finishVariant({ variantId: currentQuestionAnswers.variantId }));
  }

  get scrollToQuestion$() {
    return this._scrollToQuestion$;
  }

  selectQuestion(selectedQuestion: number): void {
    this.store.dispatch(VariantActons.goToSelectedQuestion({
      selectedQuestion: selectedQuestion}));
  }

  startVariant(variantId: string): void {
    this.store.dispatch(VariantActions.startVariant({ variantId: variantId }));
  }
}
