import {Inject, Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {SecurityService} from '../services/security.service';
import {catchError, filter, switchMap, take} from 'rxjs/operators';

@Injectable()
export class SecurityInterceptor implements HttpInterceptor {

  constructor(
    @Inject('env') private env,
    private securityService: SecurityService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authReq = req.headers.has('Authorization') ? req : this.addAuthenticationTokenToHeaders(req);
    return next.handle(authReq)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        }));
  }

  private addAuthenticationTokenToHeaders (req: HttpRequest<any>) {
    const accessToken = this.securityService.authHeader;
    if (!accessToken) { return req; }
    return req.clone({headers: new HttpHeaders({
        'Authorization': this.securityService.authHeader})});
  }
}
