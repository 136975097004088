<mat-card class="simple-message-card">
  <mat-card-content class="simple-message-content">
    <span class="simple-message-content-text">{{ message }}</span>
  </mat-card-content>
  <mat-card-actions  class="simple-message-actions">
    <span class="simple-message-actions-button">
      <button *ngIf="closeButtonEnabled" mat-flat-button>Закрыть</button>
    </span>
    <span class="simple-message-actions-button">
      <button (click)="onNextButtonClick()"  *ngIf="nextButtonEnabled" mat-flat-button color="primary">Далее</button>
    </span>
  </mat-card-actions>
</mat-card>
