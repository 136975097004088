import {HttpClient} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {forkJoin, Observable, of, Subject, throwError} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {AnswerDto} from '../../interfaces/dto/AnswerDto';
import {VariantInterface} from '../../interfaces/VariantInterface';
import {VariantDto} from '../../interfaces/dto/VariantDto';
import {QuestionService} from './question.service';

@Injectable()
export class VariantService {

  connectionRefused$: Subject<void>;
  connectionLost$: Subject<void>;

  constructor(
    private readonly http: HttpClient,
    private readonly questionService: QuestionService,
    @Inject('env') private env
  ) {  }

  static getTimeLeft(examTime: number, startTime: Date, now): number {
    const timeLeft = examTime - now + startTime.getTime();
    return timeLeft > 0 ? timeLeft : 0;
  }

  static responseDateTransform (dateString: string): Date {
    return dateString ? new Date(dateString
      .replace(/\s/, 'T')) : null;
  }

  finishVariant (variantId: string): Observable<void> {
    return this.http.post <void> (`${this.env.url}/rest/quiz/variant/${variantId}/finish`, {});
  }

  getServerTime (): Observable<Date> {
    return this.http.get(this.env.url + '/rest/quiz/ts', {
      responseType: 'text',
    }).pipe(
      catchError(err => throwError(err)),
      map(d => new Date(+d)));
  }

  getBackUrl (variantId: string): Observable<string> {
    return this.http.get(`${this.env.url}/rest/quiz/variant/${variantId}/backurl`, {
      responseType: 'text'
    }).pipe(
      catchError(err => throwError(err))
    );
  }

  getValuesFromVariantServerResponse (variantDto: VariantDto, backUrl: string, serverTime: Date): VariantInterface {
    const examTime = variantDto.examTime ? variantDto.examTime * 60000 : 0;
    const endTime = variantDto.endTime && new Date(variantDto.endTime);
    const finishTime = VariantService.responseDateTransform(variantDto.finishTime);
    const startTime = variantDto.startTime && new Date(variantDto.startTime);
    const examLength = startTime && finishTime && Math.floor((finishTime.getTime() - startTime.getTime()) / 1000 / 60);
    return {
      id: variantDto.id,
      title: variantDto.title,
      positiveCount: variantDto.positiveCount,
      mark: variantDto.mark,
      startTime,
      endTime,
      examLength,
      finishTime,
      questions: variantDto.questions.map(questionSR =>
        this.questionService.getValuesFromQuestionServerResponse(questionSR)),
      examTime,
      timeLeft: startTime ? VariantService.getTimeLeft(examTime, startTime, serverTime) : examTime,
      backUrl,
      correctPercent: Math.round(+variantDto.correctPercent * 100) / 100,
    };
  }

  loadVariant (variantId: string): Observable<VariantInterface> {
    return forkJoin([
      this.getServerTime(),
      this.getBackUrl(variantId)
    ])
      .pipe(switchMap(([serverTime, backUrl]) =>
        this.http.get <VariantDto> (`${this.env.url}/rest/quiz/variant/${variantId}`).pipe(
        map((variantDto) => {
          if (!variantDto) {
            throw new Error('there is empty variantDto');
          }
          return this.getValuesFromVariantServerResponse(variantDto, backUrl, serverTime);
        }))));
  }

  saveVariantAnswers (variantId: string, answers: AnswerDto[]): Observable<void> {
    return this.http.post <void> (`${this.env.url}/rest/quiz/variant/${variantId}/save-answer`, answers);
  }


  saveVariantAnswersOnConnectionRefusing(variant: VariantInterface) {
    const haveToSyncQuestions = variant.questions.filter(q => q.haveToSync);
    if (haveToSyncQuestions.length === 0) {
      return of(null);
    }
    return haveToSyncQuestions.map(q => this.saveVariantAnswers(variant.id, q.answers
        .map(a => ({id: a.id, selected: a.selectedByUser}))));
  }

  startVariant (variantId: string): Observable<void> {
    return this.http.post <void> (`${this.env.url}/rest/quiz/variant/${variantId}/start`, {});
  }
}
