<div class="front-screen">
  <div class="front-screen__back-button">
    <button mat-stroked-button (click)="goToIom()">
      <mat-icon svgIcon="arrow-left"></mat-icon>
      <span>Вернуться</span>
    </button>
  </div>
  <div class="front-screen__title">
    {{title}}
  </div>
  <div class="front-screen__info">
    <div class="front-screen__info__element">
      <div class="front-screen__info__element__value">
        {{numberOfQuestions}}
      </div>
      <div class="front-screen__info__element__label">
        Количество вопросов
      </div>
    </div>
    <div class="front-screen__info__element">
      <div class="front-screen__info__element__value">
        <span *ngIf="testingTime/60000 > 60">{{testingTime | date:'h':'UTC'}} ч.</span>
        <span *ngIf="!(testingTime / 60000 % 60 === 0)">{{testingTime | date:'m':'UTC'}} мин.</span>
      </div>
      <div class="front-screen__info__element__label">
        Время тестирования
      </div>
    </div>
  </div>
  <div class="front-screen__start-button">
    <button mat-raised-button color="primary" (click)="startQuiz()">
      <span>Перейти к тестированию</span>
    </button>
  </div>
</div>
