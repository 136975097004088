import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'lib-status-indicator',
  templateUrl: './status-indicator.component.html',
  styleUrls: ['./status-indicator.component.scss']
})

export class StatusIndicatorComponent implements OnInit {

  constructor() { }

  @Input()
  textLabel: string;
  @Input()
  textValue: string;
  @Input()
  mark: string;

  get getMark() {
    return this.mark ? this.mark : '';
  }

  ngOnInit(): void {
  }

}
