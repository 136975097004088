<div class="login-page">
  <mat-card>
    <div class="login-page__card__logo">
      <img class="login-page__card__logo__img" src="assets/images/logo.png">
      <span>Система тестирования ПКВК</span>
    </div>
    <form (ngSubmit)="handleSubmit($event)" class="login-page__card" [formGroup]="loginForm">
      <mat-error class="login-page__error" *ngIf="loginForm.hasError('loginRequestError')">
        {{loginForm.getError('loginRequestError')}}
      </mat-error>
      <mat-error class="login-page__error" *ngIf="loginForm.hasError('loginEmptyFields')">
        {{loginForm.getError('loginEmptyFields')}}
      </mat-error>
      <mat-form-field class="login-page__form-field" appearance="outline">
        <mat-label>Логин</mat-label>
        <input formControlName="username" type="login" matInput required/>
        <mat-error class="login-page__error" *ngIf="username.invalid">Введите логин</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Пароль</mat-label>
        <input formControlName="password" type="password" matInput required/>
        <mat-error class="login-page__error" *ngIf="username.invalid">Введите пароль</mat-error>
      </mat-form-field>
      <button class="login-page__button"
        mat-flat-button
        type="submit"
        color="primary"
      >Войти</button>
    </form>
  </mat-card>
</div>
