import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-simple-message',
  templateUrl: './simple-message.component.html',
  styleUrls: ['./simple-message.component.scss']
})
export class SimpleMessageComponent implements OnInit {

  @Input()
  closeButtonEnabled: boolean;
  @Input()
  nextButtonEnabled: boolean;
  @Input()
  message: string;
  @Output()
  nextAction = new EventEmitter <boolean> ();

  constructor() { }

  ngOnInit(): void {
  }

  onNextButtonClick() {
    this.nextAction.emit(true);
  }
}
