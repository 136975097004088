
  <div class="questionList">
    <mat-card-content>
      <div *ngFor="let question of questions, let i = index">
        <div id="item-{{i}}">
          <ng-container
            [ngTemplateOutlet]="questionListItem"
            [ngTemplateOutletContext]="{question: question, count: i}"
          ></ng-container>
        </div>
      </div>
    </mat-card-content>
  </div>


<ng-template #questionListItem let-question='question' let-count='count'>
  <div class="questionList-item" matRipple (click)="fadeOutRipple()">
    <div class="questionList-item-number-container">
      <div
        class="questionList-item-number"
        [ngClass]="{
        'questionList-item-number-notAnswered': !isQuestionAnsweredByUser(question),
        'questionList-item-number-wrong': isQuestionAnsweredByUser(question) && !question.isAnswerCorrect,
        'questionList-item-number-correct': isQuestionAnsweredByUser(question) && question.isAnswerCorrect
        }"
      >{{count + 1}}</div>
    </div>
    <div class="questionList-item-content">
      <div class="questionList-item-content-title" [innerHTML]="question.content"></div>
      <div>
        <div class="questionList-item-content-question-type" *ngIf="question.type === 'multi'">
          Требовалось выбрать НЕСКОЛЬКО правильных ответов
        </div>
        <span *ngFor="let answer of question.answers">
          <span *ngIf="answer.selectedByUser">
            <span class="questionList-item-content-answer-text">{{answer.content}}</span>
          </span>
        </span>
      </div>
    </div>
    <div class="questionList-item-status" *ngIf="quizStatus !== STATUSESENUM.finished">
      <span class="questionList-item-status-answered">
        <span *ngIf="isQuestionAnsweredByUser(question)">
          Ответ дан
        </span>
      </span>
      <span class="questionList-item-status-goto">
        <button (click)="goToQuestion(count)" mat-icon-button color="primary">
          <mat-icon [ngStyle]="{width: '12px', height: '12px', 'line-height': '12px'}" svgIcon="chevron-right"></mat-icon>
        </button>
      </span>
    </div>
  </div>
</ng-template>
