
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import {QuizzesListComponent} from './quizzes-list/quizzes-list.component';
import {AboutComponent} from './about/about.component';
import {QuizWrapperComponent} from './quiz-wrapper/quiz-wrapper.component';
import {AuthGuard} from './guards/auth.guard';
import {LoginComponent} from './login/login.component';

const routes: Routes = [
  {
    path: '',
    component: QuizzesListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'quiz-wrapper/:variantId',
    component: QuizWrapperComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    relativeLinkResolution: 'legacy',
    useHash: false,
    scrollPositionRestoration: 'disabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
