import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {SecurityService} from '../services/security.service';
import {Injectable} from '@angular/core';
import {catchError, delay, map, tap, timeout} from 'rxjs/operators';
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly securityService: SecurityService
  ) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.securityService.isLoggedIn) {
      return true;
    } else {
      return this.securityService.updateMagicImage(this.securityService.recieveToken())
        .pipe(
          map(() => true),
          catchError(() => {
            const urlEncoded = encodeURI(state.url);
            return this.router.navigate(['/login', {cb: urlEncoded}]);
          }));
    }
  }

}
