import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {QuizInterface, UserInterface} from '../interfaces';
import {OverlayContainer} from '@angular/cdk/overlay';
import {FormControl} from '@angular/forms';
import {VariantService} from '../services/variant.service';
import {map, tap} from 'rxjs/operators';
import {Observable, Subscription} from 'rxjs';
import {Variant} from '../classes/Variant';
import {Router} from '@angular/router';
import {SecurityService} from '../services/security.service';

@Component({
  selector: 'app-quiz',
  templateUrl: './quizzes-list.component.html',
  styleUrls: ['./quizzes-list.component.scss']
})
export class QuizzesListComponent implements OnInit {
  variantsList$: Observable<Variant[]>;
  displayedColumns: string[] = ['number', 'name', 'id'];
  variantsLoaded = false;
  private variantsSub: Subscription;

  constructor(
    private readonly securityService: SecurityService,
    private readonly quizService: VariantService,
  ) {}

  get isLoggedIn () {
    return this.securityService.isLoggedIn;
  }

  @HostBinding('class') componentCssClass;
  ngOnInit(): void {
     this.variantsList$ = this.quizService.getVariantsOfCurrentUser()
       .pipe(map((variants: Variant[]) => {
         this.variantsLoaded = true;
         return variants;
       }));
  }
}
