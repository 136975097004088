<div class="quiz-nav_label">Навигация по тесту</div>
<div class="quiz-nav">
  <span class="item"
    *ngFor="let q of questionsStatus"
    (click)="goToQuestion(q.count)"
  >
  <span
    class="item-test"
    [ngClass]="{
        'item-test_answered': q.answered && quizStatus !== STATUSESENUM.finished,
        'item-test_current': q.count === currentQuestion && quizStatus !== STATUSESENUM.finished,
        'item-test_finished': quizStatus === STATUSESENUM.finished,
        'item-test_finished_wright': (quizStatus === STATUSESENUM.finished) && q.right,
        'item-test_finished_false': (quizStatus === STATUSESENUM.finished) && !q.right && q.answered
       }"
  >
    <div class="item-test_div-container">
      {{q.count + 1}}
    </div>
  </span>
</span>
</div>
