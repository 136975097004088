import {Component, Input, OnInit} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {loadVariant} from '../../../actions/variant.actions';
import {STATUSESENUM, VariantStateInterface} from '../../../interfaces/VariantStateInterface';
import {
  variantCurrentQuestionNumberSelector,
  variantCurrentQuestionSelector,
  variantQuestionsNumberSelector,
  variantSelector,
  variantStatusSelector
} from '../../../reducers';
import {VariantInterface} from '../../../interfaces/VariantInterface';
import {map} from 'rxjs/operators';

@Component({
  selector: 'lib-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {
  constructor(private readonly store: Store<VariantStateInterface>) { }

  get currentQuestion$ () {
    return this.store.pipe(select(variantCurrentQuestionSelector));
  }

  get currentQuestionNumber$ (): Observable<number> {
    return this.store.pipe(select(variantCurrentQuestionNumberSelector));
  }

  get questionsListNumber$ (): Observable<number> {
    return this.store.pipe(select(variantQuestionsNumberSelector));
  }

  get variant$ (): Observable<VariantInterface> {
    return this.store.pipe(
      select(variantSelector),
      map(variantState => variantState.variant)
    );
  }

  @Input()
  variantId: string;
  variantStatus$: Observable<STATUSESENUM>;

  protected readonly STATUSESENUM = STATUSESENUM;
  handleErrorButtonClick(backUrl: string) {
    window.location.href = backUrl;
  }

  isError(status: STATUSESENUM) {
    return status === STATUSESENUM.error;
  }

  isLoading (status: STATUSESENUM) {
    return status !== STATUSESENUM.loading;
  }

  ngOnInit() {
    this.variantStatus$ = this.getVariantStatus();
    this.store.dispatch(loadVariant({variantId: this.variantId}));
  }

  getVariantStatus(): Observable<STATUSESENUM> {
    if (this.variantId) {
      return this.store.pipe(select(variantStatusSelector));
    } else {
      throwError('There is no params for loading quizzes-list');
    }
  }
}
