import * as Sentry from '@sentry/browser';
import {environment} from '../../environments/environment';

export function initSentry() {
  Sentry.init({
    dsn: environment.rnimuQUizConfig.sentryDsn,
    ignoreErrors: [
      'Non-Error exception captured',
      'Non-Error promise rejection captured'
    ],
  });
}
