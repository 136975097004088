import {BrowserModule, DomSanitizer} from '@angular/platform-browser';
import { ErrorHandler, NgModule} from '@angular/core';
import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { RnimuQuizModule } from '../../projects/rnimu-quiz/src/lib/rnimu-quiz.module';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {OverlayModule} from '@angular/cdk/overlay';
import { AboutComponent } from './about/about.component';
import {AppRoutingModule} from './app-routing.module';
import { QuizzesListComponent } from './quizzes-list/quizzes-list.component';
import {AppErrorHandler} from './handlers/app-error-handler';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatMenuModule} from '@angular/material/menu';
import {MatCardModule} from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import { QuizWrapperComponent } from './quiz-wrapper/quiz-wrapper.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { SimpleMessageComponent } from './simple-message/simple-message.component';
import {ErrorsInterceptor} from './interceptors/errors-interceptor';
import {SecurityService} from './services/security.service';
import {SecurityInterceptor} from './interceptors/security-interceptor';
import {AuthGuard} from './guards/auth.guard';
import { LoginComponent } from './login/login.component';
import {MatInputModule} from '@angular/material/input';

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    QuizzesListComponent,
    QuizWrapperComponent,
    SimpleMessageComponent,
    LoginComponent
  ],
  imports: [
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    BrowserModule,
    RnimuQuizModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    RnimuQuizModule.forRoot(environment.rnimuQUizConfig),
    StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}),
    MatIconModule,
    OverlayModule,
    AppRoutingModule,
    MatToolbarModule,
    MatMenuModule,
    MatCardModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatInputModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: SecurityInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorsInterceptor, multi: true },
    { provide: ErrorHandler, useClass: AppErrorHandler },
    SecurityService,
    AuthGuard
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor( private matIconRegistry: MatIconRegistry,
               private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon('arrow-left', this.domSanitizer
      .bypassSecurityTrustResourceUrl('./assets/icons/arrow-left.svg'));
    this.matIconRegistry.addSvgIcon('arrow-right', this.domSanitizer
      .bypassSecurityTrustResourceUrl('./assets/icons/arrow-right.svg'));
    this.matIconRegistry.addSvgIcon('bullet-list', this.domSanitizer
      .bypassSecurityTrustResourceUrl('./assets/icons/bullet-list.svg'));
    this.matIconRegistry.addSvgIcon('link', this.domSanitizer
      .bypassSecurityTrustResourceUrl('./assets/icons/link.svg'));
    this.matIconRegistry.addSvgIcon('chevron-right', this.domSanitizer
      .bypassSecurityTrustResourceUrl('./assets/icons/chevron-right.svg'));
    this.matIconRegistry.addSvgIcon('chevron-down', this.domSanitizer
      .bypassSecurityTrustResourceUrl('./assets/icons/chevron-down.svg'));
    this.matIconRegistry.addSvgIcon('rnimu-logo', this.domSanitizer
      .bypassSecurityTrustResourceUrl('./assets/icons/rnimu-logo.svg'));
    this.matIconRegistry.addSvgIcon('exit', this.domSanitizer
      .bypassSecurityTrustResourceUrl('./assets/icons/exit.svg'));
    this.matIconRegistry.addSvgIcon('correct', this.domSanitizer
      .bypassSecurityTrustResourceUrl('./assets/icons/correct.svg'));
    this.matIconRegistry.addSvgIcon('wrong', this.domSanitizer
      .bypassSecurityTrustResourceUrl('./assets/icons/wrong.svg'));
  }
}
