import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {OverlayContainer} from '@angular/cdk/overlay';
import {ScreenService} from './shared/services/screen.service';
import {Subject, Subscription} from 'rxjs';
import {VariantService} from './shared/services/variant.service';
import {variantSelector} from './reducers';
import {first, take} from 'rxjs/operators';


@Component({
  selector: 'lib-rnimu-quiz',
  template: `<lib-main-layout [variantId]="variantId"></lib-main-layout>`,
})
export class RnimuQuizComponent implements OnInit, OnChanges, OnDestroy {
  @Input() variantId: string;
  @Input() accessibleMode: boolean;
  @Input() connectionRefused$: Subject<void>;
  @Input() connectionLost$: Subject<void>;
  @Output() variantTitle: EventEmitter<string> = new EventEmitter<string>();
  @HostBinding('class') componentCssClass;
  private variantTitleSub: Subscription;

  constructor(
    private readonly store: Store,
    private readonly screenService: ScreenService,
    private readonly variantService: VariantService,
    private overlayContainer: OverlayContainer
  ) {
    this.currentScreenWidth();
  }

  ngOnDestroy() {
    this.variantTitleSub.unsubscribe();
  }

  ngOnInit(): void {
    this.variantService.connectionRefused$ = this.connectionRefused$;
    this.variantService.connectionLost$ = this.connectionLost$;
    this.variantTitleSub = this.store.pipe(
      select(variantSelector)
    ).subscribe(
      variantState => this.variantTitle.emit(variantState?.variant?.title)
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.accessibleMode.currentValue === true) {
      this.overlayContainer.getContainerElement().classList.add('rnimu-quiz-accessible-thieme');
      this.componentCssClass = 'rnimu-quiz-accessible-thieme';
    } else if (changes.accessibleMode.currentValue === false && this.componentCssClass) {
      this.overlayContainer.getContainerElement().classList.remove('rnimu-quiz-accessible-thieme');
      this.componentCssClass = this.componentCssClass.replace('rnimu-quiz-accessible-thieme', '').trim();
    }
  }

  @HostListener('window:resize', ['$event'])
  private currentScreenWidth(event?) {
    this.screenService.setScreenWidth = window.innerWidth;
  }

  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }
}
