<lib-front-screen
  *ngIf="variantStatus === STATUSENUM.notStarted"
  [backUrl]="variant.backUrl"
  [numberOfQuestions]="questionsListNumber"
  [testingTime]="timer.timeLeft"
  [title]="variant.title"
  [variantId]="variant.id"
  (startVariant)="startVariant()"
></lib-front-screen>

<mat-sidenav-container *ngIf="variantStatus !== STATUSENUM.finished"  class="quiz-page__side-nav">
  <mat-sidenav class="quiz-page__side-nav__nav" #sidenav mode="over">
    <div class="quiz-page__side-nav__nav__wrapper">
      <div class="quiz-page__side-nav__nav__main">
        <div class="quiz-page__side-nav__nav__head">
          <button class="quiz-page__side-nav__nav__head__back-button" mat-stroked-button (click)="sidenav.toggle()">
            Закрыть
          </button>
        </div>
        <div class="quiz-page__side-nav__nav__head__navigation">
          <lib-quiz-navigation class="quiz-page__side-nav__nav__navigation"
                               [quizStatus]="variantStatus"
                               [questions]="variant.questions"
                               [currentQuestion]="currentQuestionNumber"
                               (goToQuestionEmitter)="goToSelectedQuestion($event)"
          ></lib-quiz-navigation>
        </div>
      </div>
      <button class="quiz-page__side-nav__nav__wrapper__end-button" color="primary" mat-raised-button
        (click)="showFinishingConfirmDialog()"
      >Завершить тестирование</button>
    </div>
  </mat-sidenav>
  <mat-sidenav-content class="quiz-page__side-nav__content">
    <mat-card class="quiz-page__card" *ngIf="variantStatus === STATUSENUM.started">
      <div class="quiz-page__card__head">
        <div class="quiz-page__card__head__countDownTimer">
          <span class="quiz-page__card__head__countDownTimer__label">Оставшееся время</span>
          <span class="quiz-page__card__head__countDownTimer__value">{{'' + timer.timeLeft | date:'HH:mm:ss':'UTC'}}</span>
        </div>
        <div class="quiz-page__card__head__line">
          <button class="quiz-page__card__head__line__menu" mat-stroked-button
            (click)="sidenav.toggle()"
          >Меню</button>
          <div
            class="quiz-page__card__head__line__progress"
            [style.--progress]="'calc(' + (currentQuestionNumber + 1) / questionsListNumber *100 + '%)'"
          >
            <div
              class="quiz-page__card__head__line__progress__tooltip"
            >Вопрос {{currentQuestionNumber + 1}} из {{questionsListNumber}}
            </div>
            <div class="quiz-page__card__head__line__progress__tooltip-tip"></div>
            <mat-progress-bar
              class="lib-quiz-progress-bar"
              mode="determinate"
              value="{{(currentQuestionNumber + 1) / questionsListNumber *100}}">
            </mat-progress-bar>
          </div>
        </div>
      </div>
      <div id="questionAnchor" class="quiz-question-container" [ngClass]="{'quiz-question-container-exp': quizExpanded}"
           *ngIf="(variantStatus === STATUSENUM.started) && (!showQuestionsListToggle || isMobile)">
        <div class="question-animation"
             [@questionAnimation]="currentQuestionNumber"
             (@questionAnimation.start)="questionAnimationStart()"
             (@questionAnimation.done)="questionAnimationEnd()"
        >
          <lib-question
            [questionNavigationEvent$]="changeCurrentQuestion$"
            [timer]="timer"
            [currentQuestion]="currentQuestionNumber + 1"
            [numberOfQuestions]="questionsListNumber"
            [question]="currentQuestion"
            [isElementsDisabled]="questionAnimationComplete"
            [isFirstQuestion]="currentQuestionNumber === 0"
            [isLastQuestion]="currentQuestionNumber === questionsListNumber - 1"
            [isFinishButtonDisabled]="disableQuizActionsButtons"
            (goToNextQuestionEmitter)="goToNextQuestion($event)"
            (goToPreviousQuestionEmitter) = "goToPreviousQuestion($event)"
            (finishQuizEmitter)="showFinishingConfirmDialog()"
          ></lib-question>
        </div>
      </div>
    </mat-card>
  </mat-sidenav-content>
</mat-sidenav-container>

<lib-quiz
  *ngIf="variantStatus === STATUSENUM.finished"
  [variant]="variant"
  [questionsListNumber]="questionsListNumber"
  [currentQuestionNumber]="currentQuestionNumber"
  [variantStatus]="variantStatus"
  [changeCurrentQuestionSubject]="changeCurrentQuestionSubject"
  [showQuestionsListToggle]="showQuestionsListToggle"
  [disableQuizActionsButtons]="disableQuizActionsButtons"
  [isMobile]="isMobile"
  (toggleShowQuestionsEmitter)="toggleShowQuestions()"
  (showFinishingConfirmDialogEmitter)="showFinishingConfirmDialog()"
  [timer]="timer"
>
</lib-quiz>

<div *ngIf="showQuestionsList()"
     [@questionsListAnimation]="this.showQuestionsList()">
  <div *ngIf="variantStatus !== STATUSENUM.finished" class="quiz-questionsList-title">
    Список вопросов
  </div>
  <div *ngIf="variantStatus === STATUSENUM.finished" class="quiz-questionsList-title">
    Протокол тестирования
  </div>
  <mat-card>
    <lib-questions-list
      [questions]="variant.questions"
      [quizStatus]="variantStatus"
      (goToQuestionEmitter)="goToSelectedQuestionFromQuestionsList($event)"
    ></lib-questions-list>
  </mat-card>
</div>
