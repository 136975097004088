import { Injectable } from '@angular/core';

import {catchError, concatMap, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {VariantService} from './shared/services/variant.service';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as VariantActions from './actions/variant.actions';
import {Store} from '@ngrx/store';
import {VariantStateInterface} from './interfaces/VariantStateInterface';
import {variantSelector} from './reducers';
import {throwError} from 'rxjs';

@Injectable()
export class RnimuQuizEffects {
  loadVariant$ = createEffect(() => this.actions$
    .pipe(
      ofType(VariantActions.loadVariant),
      switchMap(action => this.variantService.loadVariant(action.variantId)
        .pipe(
          catchError(err => {
            VariantActions.setError({errorMsg: err?.error?.message || err?.statusText});
            return throwError(err);
          }),
          map(variant => VariantActions.receiveVariant({ variant }))))));

  startVariant$ = createEffect(() => this.actions$
    .pipe(
      ofType(VariantActions.startVariant),
      switchMap(action => this.variantService.getServerTime()
        .pipe(
          switchMap(serverTime => this.variantService.startVariant(action.variantId)
            .pipe(map(() => VariantActions.setVariantStarted({startTime: serverTime})))
        )
      )
    )));

  finishVariantQuery$ = createEffect(() => this.actions$
    .pipe(
      ofType(VariantActions.finishVariantQuery),
      switchMap(action => this.variantService.finishVariant(action.variantId)
        .pipe(map(() => VariantActions.loadVariant({variantId: action.variantId}))))));

  finishVariant$ = createEffect(() => this.actions$
    .pipe(
      ofType(VariantActions.finishVariant),
      map(action => VariantActions.finishVariantQuery({variantId: action.variantId}))));

  saveVariantAnswers$ = createEffect(() => this.actions$
    .pipe(
      ofType(VariantActions.saveVariantAnswers),
        withLatestFrom(this.store.select(variantSelector)),
        switchMap(([action, storeState]) =>
          this.variantService.saveVariantAnswers(storeState.variant.id, storeState.variant.questions[storeState.currentQuestion].answers
          .map(a => ({id: a.id, selected: a.selectedByUser})))
          .pipe(map(() => VariantActions.setQuestionSyncedStatus(
            {questionId: storeState.variant.questions[storeState.currentQuestion].id}))))
    ));

  goToSelectedQuestion$ = createEffect(() => this.actions$.pipe(
    ofType(VariantActions.goToSelectedQuestion),
    concatMap(action => [
      VariantActions.saveVariantAnswers(),
      VariantActions.selectQuestion({selectedQuestion: action.selectedQuestion})
    ])
  ));



  constructor(
    private actions$: Actions,
    private readonly store: Store<VariantStateInterface>,
    readonly variantService: VariantService
  ) {}
}
